import React from 'react';
import { ServicesImg } from '../../../assets';
import { HomeSectionScreenProps } from '../Home';
import './ServicesOffered.scss';

export const ServicesOffered: React.FC<HomeSectionScreenProps> = ({ screenId }) => {
  return (
    <div className='services-offered' id={screenId}>
      <div className='services-offered__content'>
        <img src={ServicesImg} alt='' aria-hidden />
        <div className='descriptions'>
          <h3>
            <b>Effortless Process</b> United with <b>Strong Capabilities</b>
          </h3>
          <p>
            Our services can be enabled individually or together. We believe that these services are the foundation to
            taking your business forward.​
          </p>
        </div>
      </div>
    </div>
  );
};
