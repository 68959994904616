import React from 'react';
import { ChallengeCard, Slider } from '../../../components';
import { LayersIcon, LineChartIcon, PhoneIcon, SuitcaseIcon, SupportIcon } from '../../../assets';
import useWindowDimensions from '../../../utils/WindowDimensions';
import './Challenges.scss';

type Challenge = {
  icon: string;
  title: string;
  description: string;
};

export const Challenges: React.FC = () => {
  const _mobileLayoutMaxWidth = 575;

  const _challengeList: Array<Challenge> = [
    {
      icon: PhoneIcon,
      title: 'LEGACY TECH',
      description: 'De-risking business operations by modernizing technology stack',
    },
    {
      icon: LineChartIcon,
      title: 'UNREALIZED VISIONS',
      description: 'Making visions real by applying strategy, defining technology and creating roadmaps',
    },
    {
      icon: SuitcaseIcon,
      title: 'DELIVERY ISSUES',
      description: 'Organize and optimize teams to deliver with excellence',
    },
    {
      icon: LayersIcon,
      title: 'COMPLEX PROCESS',
      description: 'Simplifying process with objective to reduce cost and maximize productive time',
    },
    {
      icon: SupportIcon,
      title: 'SLOW DIGITAL ENABLEMENT',
      description: 'Create new opportunities for growth through enhanced tech and analysis',
    },
  ];

  const { width } = useWindowDimensions();

  function _renderChallenges() {
    if (width <= _mobileLayoutMaxWidth) {
      return _renderMobileChallengeCardsSlider();
    } else {
      return _renderChallengeCards();
    }
  }

  function _renderMobileChallengeCardsSlider() {
    return (
      <Slider<Challenge>
        description='challenge'
        list={_challengeList}
        initialSlideIndex={0}
        renderSlide={(challenge, index) => _renderChallenge(challenge, index)}
      />
    );
  }

  function _renderChallengeCards() {
    return _challengeList.map((challenge, index) => _renderChallenge(challenge, index));
  }

  function _renderChallenge(challenge: Challenge, index: number) {
    return (
      <div className='challenge' key={index}>
        <ChallengeCard iconScr={challenge.icon} title={challenge.title} description={challenge.description} />
      </div>
    );
  }

  return (
    <div className='challenges'>
      <div className='challenges__content'>
        <div className='challenges__description'>
          <h3>
            We do our <b>best work</b>
          </h3>
          <h4>by tackling challenges head on.</h4>
        </div>
        <div className='challenges__list'>{_renderChallenges()}</div>
        <p className='acceptance'>
          We accept <b>your challenge</b>!
        </p>
      </div>
    </div>
  );
};
