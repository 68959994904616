import React from 'react';
import { HomeSectionScreenProps } from '../Home';
import './Companies.scss';

export const Companies: React.FC<HomeSectionScreenProps> = ({ screenId }) => {
  const companies = [
    'STARBUCKS COFFEE',
    'HALLIBURTON',
    'DIAMOND OFFSHORE',
    'MARATHON',
    'HENKEL',
    'JPMC',
    'SECURITAS',
    'METLIFE',
    'EXXON',
    'SCI',
    'CONOCOPHILLIPS',
    'EXPRESS PROFESSIONAL',
    'XECTA',
    'HUMANGO',
    'C4WORKX',
    'MOONFLOWER FARMS',
    'SCHLUMBERGER',
    'SQUATZ',
    'DURASENSE',
    'REACHAT',
    'ANA HEALTH',
    'CADENCE BANK',
    'ALEC',
    'GE OIL AND GAS',
    'BAKER HUGHES',
    'KONTAKT.IO',
    'BENJAMIN MOORE',
    'MD ANDERSON',
    'CHASE',
    'TELUS',
    'TUNECORE',
    'DISCIPLE MEDIA',
    'MICHELLIN',
    'TOYOTA',
    'WEATHERFORD'
  ];

  function _getMiddleElement(): number {
    return Math.round(companies.length / 2);
  }

  function _getHalfCompanies(isFirstHalf: boolean): Array<string> {
    if (isFirstHalf) {
      return companies.slice(0, _getMiddleElement());
    } else {
      return companies.slice(_getMiddleElement());
    }
  }

  const _renderCompanies = (isFirstHalf: boolean) => {
    return _getHalfCompanies(isFirstHalf).map((company, index) => <p key={index}>{company}</p>);
  };

  return (
    <div className='companies' id={screenId}>
      <div className='companies__content'>
        <div className='names'>{_renderCompanies(true)}</div>
        <h3>
          ACROSS ALL
          <br />
          <b>INDUSTRY</b>
          <span className='spacing' />|<span className='spacing' />
          <b>COMPANY</b>
          <span className='spacing' />|<span className='spacing' />
          <b>CHALLENGE</b>
          <br />
          <span className='text-emphasis'>
            We work with <span className='highlight'>everyone.</span>
          </span>
        </h3>
        <div className='names'>{_renderCompanies(false)}</div>
      </div>
    </div>
  );
};
