import React from 'react';
import './RoundedImage.scss';

type RoundedImageProps = {
  imgScr: string;
  stroke: 'thin' | 'thick';
  description: string;
};

export const RoundedImage: React.FC<RoundedImageProps> = ({
  imgScr,
  stroke,
  description,
}) => {
  return (
    <img className={`rounded-image ${stroke}`} src={imgScr} alt={description} />
  );
};
