import React from 'react';
import { LinkButton } from '../Button/LinkButton';
import { LogoCombinationMarkDarkImg, LogoCombinationMarkLightImg } from '../../assets';
import useWindowDimensions from '../../utils/WindowDimensions';
import './Header.scss';

export type HeaderTheme = 'light' | 'dark';

type HeaderProps = {
  theme: HeaderTheme;
  isMobileNavigationActive: boolean;
  letsTalkScreenId: string;
  onToggleMobileNavigation: () => void;
};

export const Header: React.FC<HeaderProps> = ({
  theme,
  isMobileNavigationActive,
  letsTalkScreenId,
  onToggleMobileNavigation,
}) => {
  const _mobileNavigationMinWidth = 768;

  const { width } = useWindowDimensions();

  function _renderControl() {
    if (width <= _mobileNavigationMinWidth) {
      return _renderNavMobileControl();
    } else {
      return _renderContactUs();
    }
  }

  function _renderNavMobileControl() {
    return (
      <div
        className={`mobile-nav-control ${isMobileNavigationActive ? 'active' : 'inactive'}`}
        onClick={() => onToggleMobileNavigation()}>
        <span className={theme} />
      </div>
    );
  }

  function _renderContactUs() {
    return (
      <LinkButton
        text='Lets talk!'
        url={`#${letsTalkScreenId}`}
        target='_self'
        font='regular'
        fill='hollow'
        color={theme === 'light' ? 'white' : 'secondary'}
      />
    );
  }

  return (
    <header className={`${theme} ${isMobileNavigationActive ? 'mobile-nav-active' : 'mobile-nav-inactive'}`}>
      <a href='#home'>
        <img src={theme === 'light' ? LogoCombinationMarkLightImg : LogoCombinationMarkDarkImg} alt='C4Workx logo' />
      </a>
      {_renderControl()}
    </header>
  );
};
