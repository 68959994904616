import React from 'react';
import './HomeNav.scss';

export type HomeNavSection = {
  name: string;
  id: string;
};

export type HomeNavTheme = 'light' | 'dark';

type HomeNavProps = {
  theme: HomeNavTheme;
  currentActiveSection: number;
  sections: Array<HomeNavSection>;
};

export const HomeNav: React.FC<HomeNavProps> = ({ theme, currentActiveSection, sections }) => {
  function _renderSections() {
    return sections.map((section, index) => (
      <li key={index}>
        <a className={`${theme} ${index === currentActiveSection ? 'active' : 'inactive'}`} href={`#${section.id}`}>
          {section.name}
        </a>
      </li>
    ));
  }

  return (
    <nav className='navbar'>
      <ul>{_renderSections()}</ul>
    </nav>
  );
};
