import React from 'react';
import { FounderCard, Slider } from '../../../components';
import { PhuongPhanImg, RegisGontierImg } from '../../../assets/images/founders';
import { HomeSectionScreenProps } from '../Home';
import useWindowDimensions from '../../../utils/WindowDimensions';
import './Founders.scss';

type Founder = {
  name: string;
  image: string;
  profileUrl: string;
  role: string;
  description: string;
  quote: string;
};

export const Founders: React.FC<HomeSectionScreenProps> = ({ screenId }) => {
  const _sliderMinWindowWidth = 768;
  const { width } = useWindowDimensions();

  const _founders: Array<Founder> = [
    {
      name: 'Phuong C. Phan',
      image: PhuongPhanImg,
      profileUrl: 'https://www.linkedin.com/in/phuong-chau-phan/',
      role: 'Founder, Growth & Strategy',
      description:
        'Most consulting companies out there have either creative excellence or good developers. At C4Workx, we create a culture that captivates the top 1% talents in all ability, including the best of the best in digital strategy.',
      quote:
        'The two things that are universally true to help companies excel are people and data. If you put the right data in front of the right people, it’s a game changer.',
    },
    {
      name: 'Regis Gontier',
      image: RegisGontierImg,
      profileUrl: 'https://www.linkedin.com/in/regis-gontier-33b33411/',
      role: 'FOUNDER, PEOPLE & DELIVERY',
      description:
        'Successful engagement completion isn’t a matter of luck. It requires thorough planning, listening, consistent monitoring at regular intervals, and Stakeholder involvement.',
      quote: 'I’m a great believer in the ‘baby steps’ concept – stagnation is not an option. moving forward has to be.',
    },
  ];

  function _renderPage() {
    if (width > _sliderMinWindowWidth) {
      return _renderList();
    } else {
      return _renderSlider();
    }
  }

  function _renderList() {
    return _founders.map((founder, index) => _renderFounder(founder, index));
  }

  function _renderSlider() {
    return (
      <Slider<Founder>
        description='founder'
        list={_founders}
        initialSlideIndex={0}
        renderSlide={(founder, index) => _renderFounder(founder, index)}
      />
    );
  }

  function _renderFounder(founder: Founder, index: number) {
    return (
      <div className='founder' key={index}>
        <FounderCard
          name={founder.name}
          imageScr={founder.image}
          profileUrl={founder.profileUrl}
          role={founder.role}
          description={founder.description}
          quote={founder.quote}
        />
      </div>
    );
  }

  return (
    <div className='founders' id={screenId}>
      <div className='founders__content'>
        <h3>
          Our <b>founders</b>
        </h3>
        <div className='founders__list'>{_renderPage()}</div>
      </div>
    </div>
  );
};
