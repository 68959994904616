import React from 'react';
import { LinkButton } from '../../../components';
import { HomeSectionScreenProps } from '../Home';
import './Mission.scss';

type MissionProps = HomeSectionScreenProps & {
  nextScreenId: string;
};

export const Mission: React.FC<MissionProps> = ({ screenId, nextScreenId }) => {
  return (
    <div className='mission' id={screenId}>
      <div className='mission__content'>
        <h3>
          OUR MISSION IS TO
          <b> CREATE OUTCOMES </b>
          THAT
          <br />
          <span className='text-emphasis'>
            OUR CLIENTS ARE <b>PROUD OF</b>
          </span>
        </h3>
        <p className='opportunities'>
          We unlock
          <span className='text-emphasis'> Business Opportunities </span>
          with Top 1% Talent
        </p>
        <p className='process'>Lean process | Accelerated Value</p>
        <LinkButton
          text="Let's discover together"
          url={`#${nextScreenId}`}
          target='_self'
          font='big'
          fill='filled'
          color='primary'
        />
      </div>
    </div>
  );
};
