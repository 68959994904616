import React from 'react';
import './Button.scss';

type LinkButtonProps = {
  text: string;
  url: string;
  target: '_blank' | '_self';
  font: 'regular' | 'big';
  fill: 'hollow' | 'filled';
  color: 'primary' | 'secondary' | 'white';
};

export const LinkButton: React.FC<LinkButtonProps> = ({ text, url, font, fill, color, target }) => {
  return (
    <div className='button'>
      <a className={`button__content ${fill} ${color} ${font}`} href={url} target={target} rel='noopener noreferrer'>
        {text}
      </a>
    </div>
  );
};
