import { useEffect, useState } from 'react';

type WindowDimensions = {
  width: number;
  height: number;
};

export default function useWindowDimensions(): WindowDimensions {
  const [_dimensions, _setDimensions] = useState(_getDimensions());

  useEffect(() => {
    function handleResize() {
      _setDimensions(_getDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function _getDimensions(): WindowDimensions {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  return _dimensions;
}
