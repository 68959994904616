import React from 'react';
import './DifferentialCard.scss';

type DifferentialCardProps = {
  iconScr: string;
  title: string;
  description: string;
};

export const DifferentialCard: React.FC<DifferentialCardProps> = ({
  iconScr,
  title,
  description,
}) => {
  return (
    <div className='differential_card'>
      <img src={iconScr} alt='' aria-hidden />
      <div className='differential_card-texts'>
        <h5>{title}</h5>
        <p>{description}</p>
      </div>
    </div>
  );
};
