import React from 'react';
import { DifferentialCard, Slider } from '../../../components';
import { ExtensionIcon, LaptopIcon, LightBulbIcon } from '../../../assets';
import { HomeSectionScreenProps } from '../Home';
import useWindowDimensions from '../../../utils/WindowDimensions';
import './Differentials.scss';

type Differential = {
  icon: string;
  title: string;
  description: string;
};

export const Differentials: React.FC<HomeSectionScreenProps> = ({ screenId }) => {
  const _mobileLayoutMaxWidth = 425;

  const _differentials = [
    {
      icon: LightBulbIcon,
      title: 'It starts with the ability of our people.',
      description: 'We have constant focus on the outcome.',
    },
    {
      icon: LaptopIcon,
      title: 'Your desired outcome is our personal mission.',
      description:
        'Our secret: we have perfected the science of aggressively learning your business – all to ensure we partner with you in the truest possible way.\n\nIt is only when we are in your shoes that we accurately understand the challenge and how best to solve it.',
    },
    {
      icon: ExtensionIcon,
      title: 'We are not afraid to make decisions and take action.',
      description: 'We trust and rely on our collective experience.',
    },
  ];

  const { width } = useWindowDimensions();

  function _renderDifferentialsList() {
    if (width <= _mobileLayoutMaxWidth) {
      return _renderMobileDifferentialsCardsSlider();
    } else {
      return _renderDifferentialCardsList();
    }
  }

  function _renderMobileDifferentialsCardsSlider() {
    return (
      <Slider<Differential>
        description='differential'
        list={_differentials}
        initialSlideIndex={0}
        renderSlide={(differential, index) => _renderDifferentialCard(differential, index)}
      />
    );
  }

  function _renderDifferentialCardsList() {
    return _differentials.map((differential, index) => _renderDifferentialCard(differential, index));
  }

  function _renderDifferentialCard(differential: Differential, index: number) {
    return (
      <div className='differential' key={index}>
        <DifferentialCard
          iconScr={differential.icon}
          title={differential.title}
          description={differential.description}
        />
      </div>
    );
  }

  return (
    <div className='differentials' id={screenId}>
      <div className='differentials__content'>
        <h3>
          We are <b>unique</b>
        </h3>
        <div className='differentials__list'>{_renderDifferentialsList()}</div>
        <h4>
          LEAN
          <span className='spacing' />|<span className='spacing' />
          FAST
          <span className='spacing' />|<span className='spacing' />
          ACTION
        </h4>
      </div>
    </div>
  );
};
