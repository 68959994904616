import React from 'react';
import { LinkedInIcon } from '../../assets';
import { RoundedImage } from '../RoundedImage/RoundedImage';
import './AdviserCard.scss';

type AdviserCardProps = {
  name: string;
  imageScr: string;
  profileUrl: string;
  description: string;
  quote: string;
};

export const AdviserCard: React.FC<AdviserCardProps> = ({ name, imageScr, profileUrl, description, quote }) => {
  return (
    <div className='adviser_card'>
      <RoundedImage imgScr={imageScr} stroke='thin' description={name} />
      <div className='infos'>
        <a className='profile' href={profileUrl} target='_blank' rel='noopener noreferrer'>
          <h4>{name}</h4>
          <img className='linkedin-icon' src={LinkedInIcon} alt='LinkedIn profile' />
        </a>
        <h5>Advisor</h5>
        <p className='description'>{description}</p>
        <p className='quote'>{quote}</p>
      </div>
    </div>
  );
};
