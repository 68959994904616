import React from 'react';
import { MapImg } from '../../../assets';
import useWindowDimensions from '../../../utils/WindowDimensions';
import './CompanyScope.scss';

export const CompanyScope: React.FC = () => {
  const { width } = useWindowDimensions();

  const _isColumnOrientation = () => width <= 1024;

  function _renderDescription() {
    return (
      <p className='description'>
        We’re exceptional experts without borders … our team has worked across continents bringing powerful changes
        around the world.
      </p>
    );
  }

  return (
    <div className='company-scope'>
      <div className='company-scope__content'>
        <div className='texts'>
          <h3 className='title'>
            We work with
            <br />
            everyone,
            <br />
            <span className='text-emphasis'>everywhere</span>
          </h3>
          {_isColumnOrientation() ? null : _renderDescription()}
        </div>
        <img className='map' src={MapImg} alt='Map' />
        {_isColumnOrientation() ? _renderDescription() : null}
      </div>
    </div>
  );
};
