import React from 'react';
import { LogoIcon } from '../../../assets';
import { HomeSectionScreenProps } from '../Home';
import './WhatWeDo.scss';

export const WhatWeDo: React.FC<HomeSectionScreenProps> = ({ screenId }) => {
  return (
    <div className='what-we-do' id={screenId}>
      <div className='what-we-do__content'>
        <img className='logo' src={LogoIcon} alt='C4Workx' />
        <h3>is a boutique consultancy</h3>
        <p>
          specializing in expert consulting
          <br />
          and product development through
          <br />
          technology and delivery excellence.
        </p>
      </div>
    </div>
  );
};
