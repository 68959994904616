import React from 'react';
import { LinkedInLightGrayIcon } from '../../../assets';
import { HomeSectionScreenProps } from '../Home';
import './ContactInfo.scss';
import video from '../../../assets/images/mini/waves-gold-mini-v2.mp4';

export const ContactInfo: React.FC<HomeSectionScreenProps> = ({ screenId }) => {
  return (
    <div className='contact-info' id={screenId}>
      <video src={video} autoPlay loop muted playsInline />
      <div className='contact-info__content'>
        <div className='description'>
          <h3 className='text'>What are you waiting for?</h3>
          <h3 className='text-emphasis'>Let’s work together.</h3>
        </div>
        <div className='contacts'>
          <div className='mail'>
            <a href='mailto:makeitwork@c4workx.com'>makeitwork@c4workx.com</a>
          </div>
          <div className='social-medias'>
            <a href='https://www.linkedin.com/company/c4workx/' target='_blank'>
              <img className='icon' src={LinkedInLightGrayIcon} alt="C4Workx's LinkedIn page" />
            </a>
          </div>
          <div className='find-us'>
            <div className='homes'>
              <h6>Our homes</h6>
              <p>
                United States
                <span className='spacing' />|<span className='spacing' />
                Poland
                <span className='spacing' />|<span className='spacing' />
                Canada
                <span className='spacing' />|<span className='spacing' />
                Brazil
              </p>
            </div>
            <div className='contact-us'>
              <h6>Joining C4Workx</h6>
              <p>Are you dynamite enough? Send us an e-mail</p>
            </div>
          </div>
        </div>
        <h5 className='rights-reserved'>© 2021 C4Workx. All rights reserved</h5>
      </div>
    </div>
  );
};
