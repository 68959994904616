import React from 'react';
import './ChallengeCard.scss';

type ChallengeCardProps = {
  iconScr: string;
  title: string;
  description: string;
};

export const ChallengeCard: React.FC<ChallengeCardProps> = ({
  iconScr,
  title,
  description,
}) => {
  return (
    <div className='challenge_card'>
      <img src={iconScr} alt='' aria-hidden />
      <div className='challenge_card-texts'>
        <h5>{title}</h5>
        <p>{description}</p>
      </div>
    </div>
  );
};
