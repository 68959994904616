import React, { useEffect, useLayoutEffect, useState } from 'react';
import throttle from 'lodash.throttle';
import { Header, HeaderTheme } from '../../components/Header';
import { HomeNavSection, HomeNavTheme, HomeNav, HomeSectionCounter, MobileHomeNav } from '../../components/HomeNav';
import { Welcome } from './Welcome/Welcome';
import { WhatWeDo } from './WhatWeDo/WhatWeDo';
import { CompanyScope } from './CompanyScope/CompanyScope';
import { OurPeople } from './OurPeople/OurPeople';
import { Mission } from './Mission/Mission';
import { Companies } from './Companies/Companies';
import { Challenges } from './Challenges/Challenges';
import { ServicesOffered } from './ServicesOffered/ServicesOffered';
import { Capabilities } from './Capabilities/Capabilities';
import { Differentials } from './Differentials/Differentials';
import { Founders } from './Founders/Founders';
import { Advisers } from './Advisers/Advisers';
import { ContactInfo } from './ContactInfo/ContactInfo';
import useWindowDimensions from '../../utils/WindowDimensions';
import './Home.scss';

export type HomeSectionScreenProps = {
  screenId: string;
};

export type HomeSectionInfo = HomeNavSection & {
  slideIndex: number;
};

export const Home: React.FC = () => {
  const { width } = useWindowDimensions();

  // Header
  const _darkThemmedHeaderIndexes = [1, 3, 4, 6];
  const [_headerTheme, _setHeaderTheme] = useState<HeaderTheme>('light');
  const [_mobileNavActive, _setMobileNavActive] = useState<boolean>(false);
  // Nav
  enum HomeSections {
    HOME = 'home',
    WHAT_WE_DO = 'what-we-do',
    BOUTIQUE_CONSULTANCY = 'boutique-consultancy',
    OUR_CHALLENGES = 'our-challenges',
    CAPABILITIES = 'capabilities',
    HOW_WE_DO_IT = 'how-we-do-it',
    FOUNDERS_AND_LEADERSHIP = 'founders-and-leadership',
    LETS_TALK = 'lets-talk',
  }

  const _homeSections: Array<HomeSectionInfo> = [
    {
      name: 'HOME',
      id: HomeSections.HOME,
      slideIndex: 0,
    },
    {
      name: 'WHAT WE DO',
      id: HomeSections.WHAT_WE_DO,
      slideIndex: 1,
    },
    {
      name: 'OUR CHALLENGES',
      id: HomeSections.OUR_CHALLENGES,
      slideIndex: 5,
    },
    {
      name: 'CAPABILITIES',
      id: HomeSections.CAPABILITIES,
      slideIndex: 7,
    },
    {
      name: 'HOW WE DO IT',
      id: HomeSections.HOW_WE_DO_IT,
      slideIndex: 9,
    },
    {
      name: 'FOUNDERS & LEADERSHIP',
      id: HomeSections.FOUNDERS_AND_LEADERSHIP,
      slideIndex: 10,
    },
    {
      name: 'LETS TALK!',
      id: HomeSections.LETS_TALK,
      slideIndex: 12,
    },
  ];

  const _mobileMaxWidth = 768;
  const _lightThemmedNavIndexes = [0, 2, 5, 7, 9, 12];
  const [_activeSection, _setActiveSection] = useState<number>(0);
  const [_navTheme, _setNavTheme] = useState<HomeNavTheme>('light');
  const [_showNav, _setShowNav] = useState<boolean>(false);

  useEffect(() => {
    // remove the anchor hash on load because it'll always start at first slide
    if (window.location.hash !== '') {
      window.history.replaceState('', document.title, window.location.origin);
    }
  }, []);

  useLayoutEffect(() => {
    const isFirefox = /Firefox/i.test(navigator.userAgent);
    const isIe = /MSIE/i.test(navigator.userAgent) || /Trident.*rv\:11\./i.test(navigator.userAgent);
    const scrollSensitivitySetting = 30; // increase/decrease this number to change sensitivity to events (up = less sensitive; down = more sensitive)
    const slideLockInMilliseconds = 800; // amount of time for which scroll is "locked"
    let touchStartingY = 0;

    const slides = document.querySelectorAll('.home-slide') as NodeListOf<HTMLElement>;

    // set zIndex property in descending order, for paralax to work
    for (let index = 0; index < slides.length; index++) {
      slides[index].style.zIndex = (slides.length + 1 - index).toString();
    }

    let currentSlideNumber = 0;
    let movedUp = false;
    let locked = false;

    // set timeout to temporarily block further handling of scrolling events
    function lockTimeout() {
      locked = true;
      setTimeout(function () {
        locked = false;
      }, slideLockInMilliseconds);
      _onSlideChanged(currentSlideNumber, movedUp);
    }

    function moveDown() {
      movedUp = false;
      const previousSlide = slides[currentSlideNumber];
      previousSlide.classList.remove('up-scroll');
      previousSlide.classList.add('down-scroll');
      currentSlideNumber++;
    }

    function moveUp() {
      movedUp = true;
      currentSlideNumber--;
      const currentSlide = slides[currentSlideNumber];
      currentSlide.classList.remove('down-scroll');
      currentSlide.classList.add('up-scroll');
    }

    function parallaxScroll(evt: any) {
      // determine how much scroll has moved
      let scrollDelta;
      if (isFirefox) {
        scrollDelta = evt.detail * -120;
      } else if (isIe) {
        scrollDelta = -evt.deltaY;
      } else {
        scrollDelta = evt.wheelDelta; // for all other browsers
      }

      if (!locked) {
        // determine which direction to move
        if (scrollDelta <= -scrollSensitivitySetting) {
          if (currentSlideNumber !== slides.length - 1) {
            moveDown();
            lockTimeout();
          }
        }
        if (scrollDelta >= scrollSensitivitySetting) {
          if (currentSlideNumber !== 0) {
            moveUp();
            lockTimeout();
          }
        }
      }
    }

    function onHashChange() {
      if (window.location.hash === '') {
        return;
      }

      let newHash = window.location.hash.replace('#', '');
      let targetSlideIndex =
        newHash !== HomeSections.BOUTIQUE_CONSULTANCY
          ? _homeSections.find(section => section.id === newHash)?.slideIndex
          : 2;

      if (targetSlideIndex !== undefined && currentSlideNumber !== targetSlideIndex) {
        if (targetSlideIndex > currentSlideNumber) {
          let size = targetSlideIndex - currentSlideNumber;

          for (let index = 0; index < size; index++) {
            moveDown();
          }
        } else {
          let size = currentSlideNumber - targetSlideIndex;
          for (let index = 0; index < size; index++) {
            moveUp();
          }
        }
        lockTimeout();
      }

      setTimeout(function () {
        window.history.replaceState('', document.title, window.location.origin);
      }, 500);
    }

    function onTouchStart(evt: any) {
      touchStartingY = evt?.touches[0]?.pageY;
    }

    function parallaxScrollOnTouch(evt: any) {
      let touchDelta = evt?.touches[0]?.pageY - touchStartingY;

      if (!locked) {
        // determine which direction to move
        if (touchDelta <= -scrollSensitivitySetting) {
          if (currentSlideNumber !== slides.length - 1) {
            moveDown();
            lockTimeout();
          }
        }
        if (touchDelta >= scrollSensitivitySetting) {
          if (currentSlideNumber !== 0) {
            moveUp();
            lockTimeout();
          }
        }
      }
    }

    const mousewheelEvent = isFirefox ? 'DOMMouseScroll' : 'wheel';
    window.addEventListener(mousewheelEvent, throttle(parallaxScroll, 60), false);
    window.addEventListener('touchstart', throttle(onTouchStart, 60), false);
    window.addEventListener('touchmove', throttle(parallaxScrollOnTouch, 60), false);
    window.addEventListener('hashchange', onHashChange, false);
  }, []);

  function _onSlideChanged(currentSlide: number, movedUp: boolean) {
    _setHeaderTheme(_darkThemmedHeaderIndexes.includes(currentSlide) ? 'dark' : 'light');
    _setNavTheme(_lightThemmedNavIndexes.includes(currentSlide) ? 'light' : 'dark');
    // hide navbar at first and last screen
    _setShowNav(currentSlide !== 0);
    let sectionIndex = _homeSections.findIndex(section => section.slideIndex === currentSlide);
    if (sectionIndex !== -1) {
      _setActiveSection(sectionIndex);
    } else if (movedUp) {
      let active: number = 0;

      for (let index = 1; index < _homeSections.length; index++) {
        const curr = _homeSections[index];
        if (curr.slideIndex > currentSlide) {
          break;
        }
        active = index;
      }

      _setActiveSection(active);
    }
  }

  function _toggleMobileNav() {
    _setMobileNavActive(!_mobileNavActive);
  }

  function _renderNavbar() {
    if (width > _mobileMaxWidth) {
      return (
        <div className={`navbar-container ${_showNav ? 'show' : 'hide'}`}>
          <HomeNav theme={_navTheme} currentActiveSection={_activeSection} sections={_homeSections} />
        </div>
      );
    } else {
      return (
        <div className={`mobile-navbar-container ${_mobileNavActive ? 'show' : 'hide'}`}>
          <MobileHomeNav
            theme={_headerTheme}
            currentActiveSection={_activeSection}
            sections={_homeSections}
            letsTalkScreenId={HomeSections.LETS_TALK}
            onClickAtSection={_toggleMobileNav}
          />
        </div>
      );
    }
  }

  return (
    <div>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <Welcome screenId={HomeSections.HOME} nextScreenId={HomeSections.WHAT_WE_DO} />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <Mission screenId={HomeSections.WHAT_WE_DO} nextScreenId={HomeSections.BOUTIQUE_CONSULTANCY} />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <WhatWeDo screenId={HomeSections.BOUTIQUE_CONSULTANCY} />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <CompanyScope />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <OurPeople />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <Companies screenId={HomeSections.OUR_CHALLENGES} />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <Challenges />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <ServicesOffered screenId={HomeSections.CAPABILITIES} />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <Capabilities />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <Differentials screenId={HomeSections.HOW_WE_DO_IT} />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <Founders screenId={HomeSections.FOUNDERS_AND_LEADERSHIP} />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <Advisers />
        </div>
      </section>
      <section className='home-slide'>
        <div className='content-wrapper'>
          <ContactInfo screenId={HomeSections.LETS_TALK} />
        </div>
      </section>
      <div className='header-container'>
        <Header
          theme={_headerTheme}
          isMobileNavigationActive={_mobileNavActive}
          letsTalkScreenId={HomeSections.LETS_TALK}
          onToggleMobileNavigation={_toggleMobileNav}
        />
      </div>
      {_renderNavbar()}
      {/* <div className={`sections-counter-container ${_showNav ? 'show' : 'hide'}`}>
        <HomeSectionCounter currentActiveSection={_activeSection} sectionsNumber={_homeSections.length} />
      </div> */}
    </div>
  );
};
