import React from 'react';
import { LinkedInIcon } from '../../assets';
import { RoundedImage } from '../RoundedImage/RoundedImage';
import './FounderCard.scss';

type FounderCardProps = {
  name: string;
  imageScr: string;
  profileUrl: string;
  role: string;
  description: string;
  quote: string;
};

export const FounderCard: React.FC<FounderCardProps> = ({ name, imageScr, profileUrl, role, description, quote }) => {
  return (
    <div className='founder_card'>
      <RoundedImage imgScr={imageScr} stroke='thin' description={name} />
      <div className='infos'>
        <a className='profile' href={profileUrl} target='_blank' rel='noopener noreferrer'>
          <h4>{name}</h4>
          <img className='linkedin-icon' src={LinkedInIcon} alt='LinkedIn profile' />
        </a>
        <h5>{role}</h5>
        <p className='description'>{description}</p>
        <p className='quote'>{quote}</p>
      </div>
    </div>
  );
};
