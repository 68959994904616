import React from 'react';
import { AdviserCard, Slider } from '../../../components';
import { AlBlancoImg, EricAbecassisImg, MichelleImg } from '../../../assets/images/advisers';
import useWindowDimensions from '../../../utils/WindowDimensions';
import './Advisers.scss';

type Adviser = {
  name: string;
  image: string;
  profileUrl: string;
  description: string;
  quote: string;
};

export const Advisers: React.FC = () => {
  const _sliderMinWindowWidth = 768;
  const _advisers: Array<Adviser> = [
    {
      name: 'Dr. Eric Abecassis',
      image: EricAbecassisImg,
      profileUrl: 'https://www.linkedin.com/in/eric-abecassis-182b912/',
      description:
        'Before embarking in building AI startups, Eric was the CIO and the president of Schlumberger Software division. He brings a deep understanding of technology and software engineering combined with an ability to drive a business vision and strong leadership skills.',
      quote:
        'Digitizing is the process of translating the operating model of the enterprise from the physical world to the digital world where frictions could be eliminated at scale.',
    },
    {
      name: 'Dr. Michele Bennett',
      image: MichelleImg,
      profileUrl: 'https://www.linkedin.com/in/mbennett',
      description:
        'Michele is a seasoned executive with deep academic and professional background across a myriad of subject areas: machine learning, artificial intelligence, IoT, predictive and prescriptive analyses, as well as marketing and business analytics.',
      quote:
        'Put data insights at the front and center of your business strategy and it will bring undeniable outcomes.',
    },
    {
      name: 'Al Blanco',
      image: AlBlancoImg,
      profileUrl: 'https://www.linkedin.com/in/blancostrategy/',
      description:
        'With a 30+ year track record of solid outcomes. Bringing the “know-how” of building best-in-class digital properties – from business case to design, execution, launch, and adoption. Al produced award-winning ads, websites, mobile and tablet apps, led hundreds of projects.',
      quote: "As an outside advisor, I rethink how business works in today's digitally altered reality.",
    },
  ];
  const { width } = useWindowDimensions();

  function _renderAdvisers() {
    if (width <= _sliderMinWindowWidth) {
      return _renderAdvisersSlider();
    } else {
      return _renderAdvisersList();
    }
  }

  function _renderAdvisersList() {
    return _advisers.map((adviser, index) => _renderAdviser(adviser, index));
  }

  function _renderAdvisersSlider() {
    return (
      <Slider<Adviser>
        description='adviser'
        list={_advisers}
        initialSlideIndex={0}
        renderSlide={(adviser, index) => _renderAdviser(adviser, index)}
      />
    );
  }

  function _renderAdviser(adviser: Adviser, index: number) {
    return (
      <div className='adviser' key={index}>
        <AdviserCard
          name={adviser.name}
          imageScr={adviser.image}
          profileUrl={adviser.profileUrl}
          description={adviser.description}
          quote={adviser.quote}
        />
      </div>
    );
  }

  return (
    <div className='advisers'>
      <div className='advisers__content'>
        <h3>
          Our <b>Leadership</b>
        </h3>
        <div className='advisers__list'>{_renderAdvisers()}</div>
      </div>
    </div>
  );
};
