import React, { useState } from 'react';
import { OurPeopleImg, OurPeopleLogoImg, OurPeopleMobileImg1, OurPeopleMobileImg2 } from '../../../assets';
import './OurPeople.scss';

export const OurPeople: React.FC = () => {
  const [ pageId, setPageId ] = useState(0);
  return (
    <div className='our-people'>
      <div className='our-people__content'>
        <div className='peoples__images'>
          <img className='faces' src={OurPeopleImg} alt='our people' />
        </div>
        <div className='peoples__images'>
          <img className='logo' src={OurPeopleLogoImg} alt='our people' />
        </div>
        <div className='texts'>
          <h3>
            Our power is
            <br />
            <span className='text-emphasis'>our superheroes</span>
          </h3>
          <h5>#WeAreDynamite</h5>
        </div>
      </div>
      <div className='our-people__content__mobile' onTouchEnd={() => setPageId((pageId + 1) % 2)}>
        { pageId === 0 && <img className='faces' src={OurPeopleMobileImg1} alt='our people' /> }
        { pageId === 1 && <img className='faces' src={OurPeopleMobileImg2} alt='our people' /> }
      </div>
    </div>
  );
};
