import React from 'react';
import './CapabilityCard.scss';

type CapabilityCardProps = {
  iconScr: string;
  field: string;
  title: string;
  subtitle: string;
  description: string;
  solution: string;
};

export const CapabilityCard: React.FC<CapabilityCardProps> = ({
  iconScr,
  field,
  title,
  subtitle,
  description,
  solution,
}) => {
  return (
    <div className='capability_card'>
      <div className='field-description'>
        <img src={iconScr} alt='' aria-hidden />
        <p>{field}</p>
      </div>
      <h5>{title}</h5>
      <h6>{subtitle}</h6>
      <div className='capability-description'>
        <p className='description'>{description}</p>
        <p className='solution'>{solution}</p>
      </div>
    </div>
  );
};
